'use client';
import { Fallback, logError } from '@haaretz/s-error-boundary';
import React from 'react';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
  shouldRedirect?: boolean;
}

export function ErrorPageWithoutRedirect(props: Omit<ErrorPageProps, 'shouldRedirect'>) {
  return <ErrorPage {...props} shouldRedirect={false} />;
}

export default function ErrorPage({ error, shouldRedirect = true }: ErrorPageProps) {
  // TODO: remove when this page will be ready for production
  React.useEffect(() => {
    logError(error, { componentStack: error.stack ?? '' });
    if (!/^(?:local|react-cu-).+/.test(document.location.host)) {
      if (shouldRedirect === true) {
        const params = new URLSearchParams({
          503: '',
          v2: '',
          path: document.location.pathname,
          msg: error.message,
        });
        document.location.href = `https://${document.location.host}/error?${params.toString()}`;
      }
    }
  }, []);

  return <Fallback error={error} />;
}
